<form class="w-full max-w-md mx-auto py-10 px-6 md:px-0 flex flex-col justify-center" id="get-quote-container">
  <h2 class="text-5xl text-center mb-6 flex items-center justify-center">
    <svg-icon src="assets/icons/calculator.svg" class="mr-2 w-8 h-8"></svg-icon>
    Get a Quote
  </h2>
  <div class="md:flex md:items-center mb-6">
    <div class="md:w-1/2">
      <label class="block text-center md:text-right mb-4 md:mb-0 pr-0 md:pr-4" for="inline-full-name">
        2nd Story Windows<br class="hidden md:block"> (${{ secondStoryCost }}/window)
      </label>
    </div>
    <div class="w-1/4 mx-auto md:mx-0">
      <input class="bg-gray-200 appearance-none rounded w-full py-3 px-4 text-gray-700 leading-tight" name="secondStoryAndUp" id="inline-full-name" type="number" min="0" placeholder="7" [(ngModel)]="secondStoryAndUp" (ngModelChange)="calculateTotal($event)">
    </div>
  </div>
  <div class="md:flex md:items-center mb-6">
    <div class="md:w-1/2">
      <label class="block text-center md:text-right mb-4 md:mb-0 pr-0 md:pr-4" for="inline-username">
        Ground Floor Windows<br class="hidden md:block"> (${{ groundFloorCost }}/window)
      </label>
    </div>
    <div class="w-1/4 mx-auto md:mx-0">
      <input class="bg-gray-200 appearance-none rounded w-full py-3 px-4 text-gray-700 leading-tight" name="groundFloor" id="inline-username" type="number" min="0" placeholder="11" [(ngModel)]="groundFloor"(ngModelChange)="calculateTotal($event)">
    </div>
  </div>
  <div class="md:flex md:items-center mb-6">
    <div class="md:w-1/2">
      <label class="block text-center md:text-right mb-4 md:mb-0 pr-0 md:pr-4" for="inline-username">
        Screens<br class="hidden md:block"> (${{ screenCost }}/screen)
      </label>
    </div>
    <div class="w-1/4 mx-auto md:mx-0">
      <input class="bg-gray-200 appearance-none rounded w-full py-3 px-4 text-gray-700 leading-tight" name="screens" id="inline-username" type="number" min="0" placeholder="18" [(ngModel)]="screens"(ngModelChange)="calculateTotal($event)">
    </div>
  </div>
  <div class="md:flex md:items-center mb-6">
    <div class="md:w-1/2">
      <p class="block text-center md:text-right mb-4 md:mb-0 pr-0 md:pr-4" for="inline-username">Total Cost*</p>
    </div>
    <div class="md:w-1/4 flex items-center justify-center md:block">
      <p class="bg-gray-200 appearance-none rounded px-4 py-3 text-gray-700 leading-tight">{{ totalCost | currency }}</p>
    </div>
  </div>
  <p class="text-xs text-gray-600">*This quote is not final and could be higher based on certain circumstances. Contact me to get a final price.</p>
</form>