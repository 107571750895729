<div class="relative hero-container">
  <img src="../assets/bruce_2nd_story.jpeg" alt="Bruce Cleaning Windows" class="w-screen h-1/2screen md:h-screen object-cover">
  <div class="bg-black bg-opacity-25 absolute top-0 right-0 bottom-0 left-0">
  </div>
  <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-center">
    <h1 class="text-5xl md:text-6xl lg:text-8xl leading-12 md:leading-24 lg:leading-28 text-white">Bruce's Window Cleaning</h1>
    <p class="mt-4 text-2xl lg:text-4xl text-white">Cleaning windows for the greater Waynesboro, PA area</p>
    <div class="mt-8 flex items-center justify-center">
      <a routerLink="" fragment="get-quote-container" class="bg-white text-black text-lg md:text-xl py-3 px-4 md:py-4 md:px-6 rounded-full inline-flex items-center hover:shadow-md-white transition :ease-in-out duration-300">
        <svg-icon src="assets/icons/calculator.svg" class="mr-2 w-6 h-6"></svg-icon>
        Get a Quote
      </a>
      <a href="tel:717-491-3057" class="ml-4 bg-white text-black text-xl rounded-full h-12 w-12 md:h-16 md:w-16 flex items-center justify-center hover:shadow-md-white transition :ease-in-out duration-300">
        <svg-icon src="assets/icons/phone.svg" class="w-6 h-6"></svg-icon>
      </a>
      <a href="mailto:bneterer@me.com" class="ml-4 bg-white text-black text-xl rounded-full h-12 w-12 md:h-16 md:w-16 flex items-center justify-center hover:shadow-md-white transition ease-in-out duration-300">
        <svg-icon src="assets/icons/envelope.svg" class="w-6 h-6"></svg-icon>
      </a>
    </div>
  </div>
  <div class="hidden lg:flex absolute left-1/2 transform -translate-x-1/2 flex items-center justify-center scroll-down-button">
    <button class="border-2 border-white text-white text-xl rounded-full h-16 w-16 flex items-center justify-center hover:shadow-md-white transition ease-in-out duration-300" (click)="scrollTo('services')">
      <svg-icon src="assets/icons/arrow-thin-down.svg" class="w-6 h-6 fill-current"></svg-icon>
    </button>
  </div>
</div>